import { useEffect, useState } from "react";
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import { FaInstagram, FaGithubSquare, FaLinkedinIn, FaFacebookSquare } from "react-icons/fa";
import axios  from 'axios';
function App() {
  const [position, setPosition] = useState(0);
  const [time, setTime] = useState(0);
  const [inptname, setInptname] = useState("");
  const [inptcontact, setInptcontact] = useState("");
  const [inptmail, setInptmail] = useState("");
  const [inptquery, setInptquery] = useState("");
  const [projects , setProjects] =  useState([]);
  const [blogs,setBlogs] =  useState([]);
  useEffect(() => {
    var height = window.screen.availHeight;
    var width = window.screen.availWidth;

    var container3 = document.getElementById("container3");
    var container4 = document.getElementById("container4");

    if (width > 1000) {
      container4.style.height = container3.clientHeight + "px";
      document.getElementById("backgroundimg").style.height = (height / 10) * 6 + "px";
    }
    else {
      document.getElementById("backgroundimg").style.height = (height / 10) * 4 + "px";
    }
    var a = 0;

    document.getElementById("menubtn").addEventListener("click", () => {
      if (a % 2 == 0) {
        document.getElementById("navigationcontainer").style.display = "block";
      }
      else {
        document.getElementById("navigationcontainer").style.display = "none";
      }
      a++;
    })


    axios.get("http://localhost:3001/projects").then((response) => setProjects(response.data))

    axios.get("http://localhost:3001/blogs").then((response) => setBlogs(response.data))




  })
  function right(a) {

    document.getElementById(a).scrollLeft -= 50;
  }
  function left(a) {

    document.getElementById(a).scrollLeft += 50;
  }

  function contact() {
    setInptname(document.getElementById("inputname").value);
    setInptcontact(document.getElementById("inputcontact").value);
    setInptmail(document.getElementById("inputmail").value);
    setInptquery(document.getElementById("inputquery").value);
  }

  function sendcontact() {
    axios.post('http://localhost:3001/contact', {
      name: inptname,
      contact: inptcontact,
      email: inptmail,
      query: inptquery

    })
  }
 

  return (
    <div id="container">
      <div id="navbar1">
        <center>
          <div>
            <a className="navigationbtn" href="#container3">About</a>
            <a className="navigationbtn" href="#container3">Skills</a>
            <a className="navigationbtn" href="#container3">Services</a>
            <a className="navigationbtn" href="#container5">Blogs</a>
            <a className="navigationbtn" href="#container3">Contact</a>
          </div>
        </center>
      </div>
      <div id="navbar2">
        <lord-icon
          src="https://cdn.lordicon.com/uzrqzyei.json"
          trigger="morph"
          colors="primary:white,secondary:white"
          stroke="60"
          style={{ width: "25px", height: "25px", float: "right", margin: "5%" }}
          id="menubtn">


        </lord-icon>

        <div id="navigationcontainer" style={{ width: "100%" }}>
          <span className="navigationbtn" style={{ marginLeft: "5%" }}>About</span><br />
          <span className="navigationbtn" style={{ marginLeft: "5%" }}>Projects</span><br />
          <span className="navigationbtn" style={{ marginLeft: "5%" }}>Skills</span><br />
          <span className="navigationbtn" style={{ marginLeft: "5%" }}>Services</span><br />
          <span className="navigationbtn" style={{ marginLeft: "5%" }}>Blogs</span><br />
          <span className="navigationbtn" style={{ marginLeft: "5%" }}>Contact</span><br />
        </div>

      </div>

      <div id="backgroundimg">
        <div id="mjbg">MJ</div>
        <div id="titlecont">
          <span id="title" style={{ fontFamily: 'Anurati Regular' }}>&lt; MJ DEVELOPERZ / &gt;</span>

          <br />
          <span className="subtitle" style={{ fontFamily: 'Azonix' }}>GET THE WHIZ</span>
        </div>
      </div>
      <Container style={{ zIndex: "2" }}>
        <Row style={{ marginTop: "-15%" }}>
          <Col md="6"><br />
            <div className="frames1">
              <div className="subframes1">

                <div id="profilecont">
                  <center>
                    <img src={require("./assets/profile.jpeg")} className="img-responsive" id="profile" />
                    <br /><br />
                    <span className="subtitle">Jatin Sharma</span><br /><br />
                    <span className="text1" style={{ fontFamily: "stencil", color: "silver" }}>&lt; I am a Web Developer / &gt;</span>
                    <hr style={{ color: "white" }} />

                    <div>
                      <span className="iconsbtn"><FaInstagram /></span>
                      <span className="iconsbtn"><FaGithubSquare /></span>
                      <span className="iconsbtn"><FaLinkedinIn /></span>
                      <span className="iconsbtn"><FaFacebookSquare /></span><br />
                    </div>
                    <hr style={{ color: "white" }} /><br />
                    <span className="text1">jatinsharma00325@gmail.com</span><br /><br />
                    <span className="text1">+91 9667160422</span>
                  </center>
                  <br /><br />
                </div>

              </div>
            </div>
          </Col>
          <Col md="6">
            <Row>
              <Col md="6"><br />
                <div className="frames1">
                  <div className="subframes1">
                    <br /><br />
                    <center>
                      <span className="subtitle">3 +</span>
                      <hr style={{ color: "white" }} /><br />
                      <span className="text1">Completed Projects</span>
                    </center>
                    <br />
                  </div>
                </div>
              </Col>
              <Col md="6"><br />
                <div className="frames1">
                  <div className="subframes1">
                    <br /><br />
                    <center>
                      <span className="subtitle">3 +</span>
                      <hr style={{ color: "white" }} /><br />
                      <span className="text1">Ongoing Projects</span>
                    </center>
                    <br />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            {/* <span className="subtitle">&lt;!--Projects------------------------------------------------&gt;</span> */}

            <div className="frames2">
              <div className="subframes2">
                <br /><br />
                <span className="text1" style={{ marginLeft: "5%" }}>Completed Projects</span><br />
                <div className="carousel" id="carousel1">
                  {projects.map((element, index) => (
                 <div key={index} style={{ borderRadius: "7px", marginRight: "20px", background: "white" }}><img src={require("./assets/"+element.images)} style={{ width: "280px" }} className="card-img" /></div>
                 
                  
                  ))}

                </div>
                <center>
                  <div className="projectnavigation">
                    <button style={{ background: "none", border: "none" }} onClick={() => right("carousel1")}><span className="subtitle">&lt;</span></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button style={{ background: "none", border: "none" }} onClick={() => left("carousel1")} > <span className="subtitle">&gt;</span></button>
                  </div></center>

                <br />
                {/* <span className="text1" style={{ marginLeft: "5%" }}>Ongoing Projects</span>
                <div className="carousel" id="carousel2">
                  {data.map((element) => (
                    <div style={{ padding: "30px", borderRadius: "7px", marginRight: "20px", background: "white" }}><img src="https://baggatraders.in/assets/logo/btlogo.png" style={{ width: "200px" }} className="card-img" /></div>
                  ))}
                </div>
                <br /><br /> */}
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="6"><br />
            <div className="frames2" id="container3">
              <div className="subframes2">
                <br /><br /><br />
                <div className="datacontainer">
                  <span className="text1">About Us</span>
                  <hr style={{ color: "white" }} /><br />
                  <span className="text2">
                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

                  </span>

                  <hr style={{ color: "white" }} /><br /><br />

                  <span className="text1">Skills</span>
                  <hr style={{ color: "white" }} /><br />
                  <Row>
                    <Col md="6">
                      <span className="text1">HTML</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">CSS</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">JAVASCRIPT</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">BOOTSTRAP</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">PHP</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                    </Col>
                    <Col md="6">
                      <span className="text1">MYSQL</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">MONGO DB</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">REACT JS</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">NODE JS</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                      <span className="text1">EXPRESS JS</span><br />
                      <div className="cell">
                        <div className="subcell" style={{ width: "70%" }}></div>
                      </div><br /><br />
                    </Col>
                  </Row>


                </div>
              </div>
            </div>
          </Col>
          <Col md="6"><br />
            <div className="frames2" id="container4">
              <div className="subframes2">
                <br /><br /><br />
                <div className="datacontainer">
                  <span className="text1">Services</span>
                  <hr style={{ color: "white" }} /><br />
                  <span className="text2">
                  In the age of interconnectivity, web development services are inevitably on the rise. Businesses of all shapes and sizes are putting their best efforts into maximizing the quality of their online presence, frequently by outsourcing IT services or augmenting their in-house IT staff with expert engineers.

However, web development covers a massive range of solutions, most of which are customized to the client’s needs. Depending on the project’s requirements, they can include solutions like web application development, cybersecurity, testing, maintenance, consultancy, and even UI/UX design. That means that a senior web development team can completely reimagine your business and provide you a competitive edge in today’s business landscape. 

Let’s take a closer look at which are the most common web development services in the market today.

                  </span>

                  <hr style={{ color: "white" }} /><br /><br />

                  <span className="text1">Contact </span>
                  <hr style={{ color: "white" }} /><br /><br />
                  <div className="cell">
                    <input type="text" className="input" placeholder="Name" onChange={contact} id="inputname" required></input>
                  </div><br /><br />

                  <div className="cell">
                    <input type="contact" className="input" placeholder="Contact" onChange={contact} id="inputcontact" required></input>
                  </div><br /><br />

                  <div className="cell">
                    <input type="email" className="input" placeholder="E-mail" onChange={contact} id="inputmail" required></input>
                  </div><br /><br />

                  <div className="cell">
                    <textarea className="input" placeholder="Messege here..." onChange={contact} id="inputquery" required />
                  </div><br /><br />
                  <button className="text1" style={{ backgroundImage: "linear-gradient(to right,#cb69c1,#6c72cb)", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", borderRadius: "7px", paddingTop: "10px" }}>Ask Now</button>
                </div>
                <br /><br />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <div className="frames2" style={{ padding: "3px" }} id="container5">
          <div className="subframes2">
            <br /><br /><br />
            <div className="datacontainer" style={{ width: "80%" }}>
              <span className="text1">Our Blogs</span>
              <hr style={{ color: "white" }} /><br />

              <div className="carousel" id="carousel2">
                {blogs.map((element, index) => (
                  <div key={index} style={{ borderRadius: "7px", marginRight: "20px", border: "1px solid white" }}><img src={require("./assets/"+element.thumbnail)} style={{ backgroundColor: "white", width: "400px", borderRadius: "7px" }} className="card-img" />

                    <div style={{ padding: "33px" }}>
                      <span className="text2">{element.title} </span>
                    </div>
                  </div>
                ))}

              </div>
              <center>
                <div className="projectnavigation">

                  <button style={{ background: "none", border: "none" }} onClick={() => right("carousel2")}><span className="subtitle">&lt;</span></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button style={{ background: "none", border: "none" }} onClick={() => left("carousel2")} ><span className="subtitle">&gt;</span></button>

                </div>
              </center>
            </div>
            <br /><br />
          </div>
        </div>
        <br /><br /><br />
      </Container>
      <div className="frames2" style={{ padding: "3px", boxShadow: " 3px 3px 15px 1px#cb69c1,-3px -3px 15px 1px #6c72cb" }} >
        <div className="subframes2">
          <br />
          <div className="datacontainer" style={{ width: "50%" }}>
            <center>
              <Row>
                <Col md="4">
                  <span className="text1" style={{ fontFamily: "Anurati Regular" }}>MJ DEVELOPERZ</span><br />
                  <span className="text2" style={{ fontFamily: "Azonix" }}>Get The Whiz</span>
                </Col>
                <Col md="4">
                  <span className="text1" style={{ fontFamily: "Anurati Regular" }}>FOLLOW ON-</span><br />
                  <span className="text2"> <div>
                    <span className="iconsbtn"><FaInstagram /></span>
                    <span className="iconsbtn"><FaGithubSquare /></span>
                    <span className="iconsbtn"><FaLinkedinIn /></span>
                    <span className="iconsbtn"><FaFacebookSquare /></span><br />
                  </div></span>
                </Col>
                <Col md="4">
                  <span className="text1" style={{ fontFamily: "Anurati Regular" }}>NEW DELHI</span><br />
                  <span className="text2" style={{ fontFamily: "AZONIX" }}>INDIA</span>
                </Col>
              </Row>
            </center>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default App;
